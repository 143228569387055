// @ts-check

import axios from "../../middleware/axios.middelware";
const URL_SURGERY_ASSIGNMENT = "/orchestrator/surgeryassignment";
const URL_PRACTITIONER_EXPERIENCE = "/orchestrator/pracroles/practitioners/experience";
const URL_SURGEON_OPTIONS = "/orchestrator/pracroles/practitioners";

const MEDICAL_PROFESSIONAL_GROUP = "medicalProfessionalGroup";
const SURGEON = "surgeon";

/**
 * @param {string} organizationId
 * @return {Promise<{data: {ok: boolean, data: Array<ProcedureInfo>}}>}
 */
export const fetchSurgeryAssignmentList = (organizationId) => {
    return axios.get(URL_SURGERY_ASSIGNMENT, {params: {organizationId}});
};

/**
 * @typedef SurgeryAssignmentPatchResponse
 * @property {Boolean} ok
 * @property {SurgeryAssignmentPatchData} data
 */

/**
 * @param {string} organizationId
 * @param {ProcedurePatchData} data
 * @return {Promise<{data: SurgeryAssignmentPatchResponse}>}
 */
export const patchSurgeryAssignmentList = (organizationId, data) => {
    const body = {
        organizationId,
        ...data
    };
    return axios.patch(URL_SURGERY_ASSIGNMENT, body);
};

/**
 * fetch practitioner experience
 * @param {String} organizationId
 * @param {Array<String>} practitionerIds
 * @return {Promise<{data: {ok: Boolean, data: Array<PractitionerExperience>}}>}
 */
export const fetchSurgeonExperience = (organizationId, practitionerIds) => {
    return axios.get(URL_PRACTITIONER_EXPERIENCE, {
        params: {practitionerIds, organizationId}
    });
};

/** @typedef {import("axios").AxiosResponse<{data: object, ok: boolean}>} ResponseSurgeonOptions */

/**
 * @typedef {object} SurgeonOptionParams
 * @property {string} organizationId
 */
/**
 * fetch surgeon options to be shonw in the SurgeryAssignmentManageDialog
 * @param {SurgeonOptionParams} params
 * @return {Promise<ResponseSurgeonOptions>} // data as object of key: practitionerId, value: pracroleId
 */
export const fetchSurgeonOptionsList = (params) =>
    axios.get(URL_SURGEON_OPTIONS, {params: {...params, [MEDICAL_PROFESSIONAL_GROUP]: SURGEON}});
