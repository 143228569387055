// @ts-check
const ActionTypes = {
    LOAD_PRINT_DATES_REQUEST: "print/LOAD_PRINT_DATES_REQUEST",
    LOAD_PRINT_DATES_SUCCESS: "print/LOAD_PRINT_DATES_SUCCESS",
    LOAD_PRINT_DATES_FAILURE: "print/LOAD_PRINT_DATES_FAILURE",
    SAVE_PRINT_DATES_REQUEST: "print/SAVE_PRINT_DATES_REQUEST",
    SAVE_PRINT_DATES_SUCCESS: "print/SAVE_PRINT_DATES_SUCCESS",
    SAVE_PRINT_DATES_FAILURE: "print/SAVE_PRINT_DATES_FAILURE",
    RESET_SAVE_PRINT_DATES_STATUS: "print/RESET_SAVE_PRINT_DATES_STATUS"
};

export default ActionTypes;
