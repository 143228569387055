import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./timeslot_table_action_types";

const DEFAULT_INTERVAL = 7; // days

const initialState = {
    data: [],
    loadStatus: STATUS.IDLE,
    error: null,
    date: null,
    interval: DEFAULT_INTERVAL
};

const handleLoadRequest = (state) => ({
    ...state,
    loadStatus: STATUS.PENDING,
    error: null
});

const handleLoadFailure = (state, {error}) => ({
    ...state,
    loadStatus: STATUS.REJECTED,
    error
});

const handleLoadSuccess = (state, {payload}) => ({
    ...state,
    loadStatus: STATUS.RESOLVED,
    error: null,
    data: payload
});

const handleChangeDate = (state, {date, interval}) => ({
    ...state,
    date,
    interval
});

const handlers = {
    [ActionTypes.LOAD_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_FAILURE]: handleLoadFailure,
    [ActionTypes.CHANGE_DATE]: handleChangeDate
};
export default createReducer(initialState, handlers);
