/**
 * @fileoverview redux selectors for timelots
 */

const selectTimeslots = (state) => state.timeslots;

const selectLoadError = (state) => selectTimeslots(state).loadError;
const selectLoadStatus = (state) => selectTimeslots(state).loadStatus;
const selectData = (state) => selectTimeslots(state).data;
const selectOpRoomIds = (state) => selectTimeslots(state).opRooms;
const selectCoreValues = (state) => selectTimeslots(state).coreValues;
const selectPreCheck = (state) => selectTimeslots(state).preCheck;
const selectCheckStatus = (state) => selectTimeslots(state).checkStatus;
const selectSaveStatus = (state) => selectTimeslots(state).saveStatus;
const selectDeleteStatus = (state) => selectTimeslots(state).deleteStatus;
const selectSelectedDate = (state) => selectTimeslots(state).selectedDate;

export {
    selectLoadError,
    selectLoadStatus,
    selectData,
    selectOpRoomIds,
    selectCoreValues,
    selectPreCheck,
    selectCheckStatus,
    selectSaveStatus,
    selectDeleteStatus,
    selectSelectedDate
};
