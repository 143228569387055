// @ts-check
import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import {ActionTypes} from "./surgery_assignment_action_types";
/** @typedef {import("redux").Action} Action */

/** @type SurgeryAssignmentState */
const initialState = {
    data: [],
    error: null,
    loadingStatus: STATUS.IDLE,
    warningCount: 0,
    modifyStatus: STATUS.IDLE,
    patchErrors: [],
    loadingExperienceStatus: STATUS.IDLE,
    practitionerExperience: [],
    isSurgeonSelectorOpen: false,
    surgeonOptions: [],
    loadingOptionsStatus: STATUS.IDLE,
    loadingOptionsError: null
};

/**
 * A function to handle the the loading surgery assignments success
 *
 * @param {SurgeryAssignmentState} state
 * @param {object} action
 * @param {array} action.payload
 * @return {SurgeryAssignmentState}
 */
const handleLoadSurgeryAssignmentSuccess = (state, {payload}) => ({
    ...state,
    data: payload,
    error: null,
    loadingStatus: STATUS.RESOLVED
});

/**
 * A function to handle the the loading surgery assignments request
 *
 * @param {SurgeryAssignmentState} state
 * @return {SurgeryAssignmentState}
 */
const handleLoadSurgeryAssignmentRequest = (state) => ({...state, loadingStatus: STATUS.PENDING, error: null});

/**
 * A function to handle the the loading surgery assignments failure
 *
 * @param {SurgeryAssignmentState} state
 * @param {object} action
 * @param {string} action.error
 * @return {SurgeryAssignmentState}
 */
const handleLoadSurgeryAssignmentFailure = (state, {error}) => ({...state, loadingStatus: STATUS.REJECTED, error});

/**
 * A function to handle the warnings count for surgery assignment
 *
 * @param {SurgeryAssignmentState} state
 * @return {SurgeryAssignmentState}
 */
const handleSurgeryAssignmentWarningCount = (state, {payload}) => ({...state, warningCount: payload});

/**
 * A function to handle the the modifying surgery assignments success
 *
 * @param {SurgeryAssignmentState} state
 * @return {SurgeryAssignmentState}
 */
const handleModifySurgeryAssignmentSuccess = (state) => ({
    ...state,
    error: null,
    modifyStatus: STATUS.RESOLVED
});

/**
 * A function to handle the the modifying surgery assignments request
 *
 * @param {SurgeryAssignmentState} state
 * @return {SurgeryAssignmentState}
 */
const handleModifySurgeryAssignmentRequest = (state) => ({...state, modifyStatus: STATUS.PENDING, error: null});

/**
 * A function to handle the the loading surgery assignments failure
 *
 * @param {SurgeryAssignmentState} state
 * @param {object} action
 * @param {string} action.error
 * @return {SurgeryAssignmentState}
 */
const handleModifySurgeryAssignmentFailure = (state, {error}) => ({...state, modifyStatus: STATUS.REJECTED, error});

/**
 * A function to handle if there is an error on save
 * @param {SurgeryAssignmentState} state
 * @param {object} action
 * @param {Array<SurgeryAssignmentPatchData>} action.errors
 * @return {SurgeryAssignmentState}
 */
const handleSurgeryAssignmentError = (state, {errors}) => ({...state, patchErrors: errors});

/**
 * A function to handle the surgeon experience success
 *
 * @param {SurgeryAssignmentState} state
 * @param {object} action
 * @param {Array<PractitionerExperience>} action.payload
 * @return {SurgeryAssignmentState}
 */
const handleLoadSurgeonExperienceSuccess = (state, {payload}) => ({
    ...state,
    practitionerExperience: payload,
    error: null,
    loadingExperienceStatus: STATUS.RESOLVED
});

/**
 * A function to handle the surgeon experience request
 *
 * @param {SurgeryAssignmentState} state
 * @return {SurgeryAssignmentState}
 */
const handleLoadSurgeonExperienceRequest = (state) => ({...state, loadingExperienceStatus: STATUS.PENDING, error: null});

/**
 * A function to handle the surgeon experience failure
 *
 * @param {SurgeryAssignmentState} state
 * @param {object} action
 * @param {string} action.error
 * @return {SurgeryAssignmentState}
 */
const handleLoadSurgeonExperienceFailure = (state, {error}) => ({...state, loadingExperienceStatus: STATUS.REJECTED, error});

/**
 * A function to handle isSurgeonSelectorOpen
 * @param {SurgeryAssignmentState} state
 * @param {object} action
 * @param {Boolean} action.isOpen
 * @return {SurgeryAssignmentState}
 */
const handleIsSurgeonSelectorOpen = (state, {isOpen}) => ({...state, isSurgeonSelectorOpen: isOpen});

/**
 * A function to handle the the surgeon options success action
 *
 * @param {SurgeryAssignmentState} state
 * @param {object} action
 * @param {object} action.payload
 * @return {SurgeryAssignmentState}
 */
const handleLoadSurgeonOptionsSuccess = (state, {payload}) => ({
    ...state,
    surgeonOptions: payload,
    loadingOptionsError: null,
    loadingOptionsStatus: STATUS.RESOLVED
});

/**
 * A function to handle the surgeon options  request
 *
 * @param {SurgeryAssignmentState} state
 * @return {SurgeryAssignmentState}
 */
const handleLoadSurgeonOptionsRequest = (state) => ({...state, loadingOptionsStatus: STATUS.PENDING, loadingOptionsError: null});

/**
 * A function to handle the surgeon options  failure
 *
 * @param {SurgeryAssignmentState} state
 * @param {object} action
 * @param {string} action.error
 * @return {SurgeryAssignmentState}
 */
const handleLoadSurgeonOptionsFailure = (state, {error}) => ({...state, loadingOptionsStatus: STATUS.REJECTED, loadingOptionsError: error});

const handlers = {
    [ActionTypes.LOAD_SURGERY_ASSIGNMENT_SUCCESS]: handleLoadSurgeryAssignmentSuccess,
    [ActionTypes.LOAD_SURGERY_ASSIGNMENT_REQUEST]: handleLoadSurgeryAssignmentRequest,
    [ActionTypes.LOAD_SURGERY_ASSIGNMENT_FAILURE]: handleLoadSurgeryAssignmentFailure,
    [ActionTypes.SURGERY_ASSIGNMENT_HAS_WARNINGS]: handleSurgeryAssignmentWarningCount,
    [ActionTypes.MODIFY_SURGERY_ASSIGNMENT_SUCCESS]: handleModifySurgeryAssignmentSuccess,
    [ActionTypes.MODIFY_SURGERY_ASSIGNMENT_REQUEST]: handleModifySurgeryAssignmentRequest,
    [ActionTypes.MODIFY_SURGERY_ASSIGNMENT_FAILURE]: handleModifySurgeryAssignmentFailure,
    [ActionTypes.SURGERY_ASSIGNMENT_ERRORS_ON_SAVE]: handleSurgeryAssignmentError,
    [ActionTypes.LOAD_SURGEON_EXPERIENCE_SUCCESS]: handleLoadSurgeonExperienceSuccess,
    [ActionTypes.LOAD_SURGEON_EXPERIENCE_REQUEST]: handleLoadSurgeonExperienceRequest,
    [ActionTypes.LOAD_SURGEON_EXPERIENCE_FAILURE]: handleLoadSurgeonExperienceFailure,
    [ActionTypes.SET_IS_SURGEON_SELECTOR_OPEN]: handleIsSurgeonSelectorOpen,
    [ActionTypes.LOAD_SURGEON_OPTIONS_SUCCESS]: handleLoadSurgeonOptionsSuccess,
    [ActionTypes.LOAD_SURGEON_OPTIONS_REQUEST]: handleLoadSurgeonOptionsRequest,
    [ActionTypes.LOAD_SURGEON_OPTIONS_FAILURE]: handleLoadSurgeonOptionsFailure
};

export const surgeryAssignmentReducer = createReducer(initialState, handlers);
