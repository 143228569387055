import {createReducer} from "../../redux/utils/reducer";
import ActionTypes from "./room_planner_action_types";

const DEFAULT_INTERVAL = 7; // days

const initialState = {
    date: null,
    interval: DEFAULT_INTERVAL,
    reload: false
};

const handleChangeDate = (state, {date, interval}) => ({
    ...state,
    date,
    interval
});

const handleReloadRoomPlanner = (state, {reload}) => ({
    ...state,
    reload: reload
});

const handlers = {
    [ActionTypes.CHANGE_DATE]: handleChangeDate,
    [ActionTypes.RELOAD_ROOM_PLANNER]: handleReloadRoomPlanner
};

export default createReducer(initialState, handlers);
