// @ts-check
import {loadNamesAction} from "../../components/private_data/private_data_actions";
import {countWarnings} from "../../components/surgery_assignment_canvas/helpers";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {ActionTypes} from "./surgery_assignment_action_types";
import {
    fetchSurgeonExperience,
    fetchSurgeonOptionsList,
    fetchSurgeryAssignmentList,
    patchSurgeryAssignmentList
} from "./surgery_assignment_api";

/** @typedef {import("redux").Dispatch} Dispatch */
/** @typedef {import("redux").Action} Action */
/** @typedef {import("./surgery_assignment_action_types").ActionTypes} SurgeryAssignmentActionTypes  */

/**
 * An action creator to start the request to load the surgery assignment data
 *
 * @return {{type: SurgeryAssignmentActionTypes}}
 */
const loadSurgeryAssignmentRequestAction = () => ({
    type: ActionTypes.LOAD_SURGERY_ASSIGNMENT_REQUEST
});

/**
 * An action creator for the success
 *
 * @param {array} payload
 * @return {{type: SurgeryAssignmentActionTypes, payload: array}}
 */
const loadSurgeryAssignmentSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SURGERY_ASSIGNMENT_SUCCESS,
    payload
});

/**
 * An action creator for the error
 *
 * @param {string} error
 * @return {{type: SurgeryAssignmentActionTypes, error: string}}
 */
const loadSurgeryAssignmentFailureAction = (error) => ({
    type: ActionTypes.LOAD_SURGERY_ASSIGNMENT_FAILURE,
    error
});

/**
 * An action creator to set the number of surgeries without at least one practitioner
 *
 * @param {number} payload The number of surgeries without at least one practitioner
 * @return {{type: SurgeryAssignmentActionTypes, payload: number}}
 */
export const setSurgeryAssignmentWarnings = (payload) => ({
    type: ActionTypes.SURGERY_ASSIGNMENT_HAS_WARNINGS,
    payload
});

/**
 * Load the surgery assignment from the data base
 *
 * @return {any}
 */
export const loadSurgeryAssignmentList = () => (/** @type {Dispatch}} */ dispatch, getState) => {
    dispatch(loadSurgeryAssignmentRequestAction());
    const organizationId = selectCurrentOrganizationId(getState());

    fetchSurgeryAssignmentList(organizationId)
        .then(({data}) => {
            const allIds = data.data
                .map(({assignedPractitioners}) => assignedPractitioners.map(({practitioners}) => practitioners))
                .flat(2); /** @todo #14864: check if we need to flatten more than two levels in some case ??? */

            const idsNoDuplicates = [...new Set(allIds)];
            dispatch(loadSurgeryAssignmentSuccessAction(data.data));

            // load names
            dispatch(loadNamesAction(organizationId, "practitioner", idsNoDuplicates, false));

            // check for warnings
            const warnings = countWarnings(data.data);
            dispatch(setSurgeryAssignmentWarnings(warnings));
        })
        .catch((error) => {
            dispatch(loadSurgeryAssignmentFailureAction(error));
        });
};

/**
 * An action creator to start the request to modify the surgery assignment data
 *
 * @return {{type: SurgeryAssignmentActionTypes}}
 */
const modifySurgeryAssignmentRequestAction = () => ({
    type: ActionTypes.MODIFY_SURGERY_ASSIGNMENT_REQUEST
});

/**
 * An action creator for the success
 *
 * @return {{type: SurgeryAssignmentActionTypes}}
 */
const modifySurgeryAssignmentSuccessAction = () => ({
    type: ActionTypes.MODIFY_SURGERY_ASSIGNMENT_SUCCESS
});

/**
 * An action creator for the error
 *
 * @param {string} error
 * @return {{type: SurgeryAssignmentActionTypes, error: string}}
 */
const modifySurgeryAssignmentFailureAction = (error) => ({
    type: ActionTypes.MODIFY_SURGERY_ASSIGNMENT_FAILURE,
    error
});
/**
 * @callback ReduxAction
 * @param {Dispatch} dispatch
 * @param {() => any} getState
 */
/**
 * Load the surgery assignment from the data base
 *
 * @param {ProcedurePatchData} data
 * @return {ReduxAction}
 */
export const modifySurgeryAssignmentList = (data) => (dispatch, getState) => {
    dispatch(modifySurgeryAssignmentRequestAction());
    const organizationId = selectCurrentOrganizationId(getState());
    const allPractitionersIds = data.allDisplayedPractitioners;

    patchSurgeryAssignmentList(organizationId, data)
        .then(({data}) => {
            if (data.ok) {
                // refetch
                dispatch(loadSurgeryAssignmentList());
                if (allPractitionersIds?.length) {
                    dispatch(loadSurgeonExperienceAction(allPractitionersIds));
                }
                dispatch(hasErrorOnSaveAction([])); // reset errors
            } else {
                dispatch(hasErrorOnSaveAction(data.data.errors || []));
            }
            dispatch(modifySurgeryAssignmentSuccessAction());
        })
        .catch((error) => {
            dispatch(modifySurgeryAssignmentFailureAction(error));
        });
};

/**
 * An action creator for errors when saving the changes on the SurgeryAssignmentManageDialog
 *
 * @param {Array<SurgeryAssignmentPatchError>} errors Whether there is an empty surgery assignment
 * @return {{type: SurgeryAssignmentActionTypes, errors: Array<SurgeryAssignmentPatchError>}}
 */
export const hasErrorOnSaveAction = (errors) => ({
    type: ActionTypes.SURGERY_ASSIGNMENT_ERRORS_ON_SAVE,
    errors
});
/**
 * An action creator to start the request to fetch the surgeon experience data
 *
 * @return {{type: SurgeryAssignmentActionTypes}}
 */
const loadSurgeonExperienceRequestAction = () => ({
    type: ActionTypes.LOAD_SURGEON_EXPERIENCE_REQUEST
});

/**
 * An action creator for the success
 * @param {Array<PractitionerExperience>} payload
 * @return {{type: SurgeryAssignmentActionTypes, payload: Array<PractitionerExperience>}}
 */
const loadSurgeonExperienceSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SURGEON_EXPERIENCE_SUCCESS,
    payload
});

/**
 * An action creator for the error
 *
 * @param {string} error
 * @return {{type: SurgeryAssignmentActionTypes, error: string}}
 */
const loadSurgeonExperienceFailureAction = (error) => ({
    type: ActionTypes.LOAD_SURGEON_EXPERIENCE_FAILURE,
    error
});

/**
 * Load the surgeon experience from the orchestrator
 *
 * @param {Array<string>} practitionerIds
 * @return {any}
 */
export const loadSurgeonExperienceAction = (practitionerIds) => (/** @type {Dispatch}} */ dispatch, getState) => {
    dispatch(loadSurgeonExperienceRequestAction());
    const organizationId = selectCurrentOrganizationId(getState());

    fetchSurgeonExperience(organizationId, practitionerIds)
        .then(({data}) => {
            if (data.ok) {
                // refetch
                dispatch(loadSurgeonExperienceSuccessAction(data.data));
            }
        })
        .catch((error) => {
            dispatch(loadSurgeonExperienceFailureAction(error));
        });
};

/**
 * An action creator for isSurgeonSelectorOpen flag
 *
 * @param {Boolean} isOpen Whether the surgeon selector is open
 * @return {{type: SurgeryAssignmentActionTypes, isOpen: Boolean}}
 */
export const setIsSurgeonSelectorOpen = (isOpen) => ({
    type: ActionTypes.SET_IS_SURGEON_SELECTOR_OPEN,
    isOpen
});

/**
 * An action creator to start the request to load the surgery assignment data
 *
 * @return {{type: SurgeryAssignmentActionTypes}}
 */
const loadSurgeonOptionsRequestAction = () => ({
    type: ActionTypes.LOAD_SURGEON_OPTIONS_REQUEST
});

/**
 * An action creator for the success
 *
 * @param {array} payload
 * @return {{type: SurgeryAssignmentActionTypes, payload: array}}
 */
const loadSurgeonOptionsSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SURGEON_OPTIONS_SUCCESS,
    payload
});

/**
 * An action creator for the error
 *
 * @param {string} error
 * @return {{type: SurgeryAssignmentActionTypes, error: string}}
 */
const loadSurgeonOptionsFailureAction = (error) => ({
    type: ActionTypes.LOAD_SURGEON_OPTIONS_FAILURE,
    error
});

/**
 * Load the surgery assignment from the data base
 *
 * @return {any}
 */
export const loadSurgeonOptionsList = () => (/** @type {Dispatch}} */ dispatch, getState) => {
    dispatch(loadSurgeonOptionsRequestAction());
    const organizationId = selectCurrentOrganizationId(getState());
    const params = {organizationId};

    fetchSurgeonOptionsList(params)
        .then(({data}) => {
            const idsNoDuplicates = [...new Set(Object.keys(data.data))];
            dispatch(loadSurgeonOptionsSuccessAction(data.data));

            // load names
            dispatch(loadNamesAction(organizationId, "practitioner", idsNoDuplicates, false));
        })
        .catch((error) => {
            dispatch(loadSurgeonOptionsFailureAction(error));
        });
};
