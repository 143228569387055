import {createSelector} from "reselect";

/**
 * A selector for the surgeryAssignmentList slice in the store
 *
 * @param {object} state
 * @return {SurgeryAssignmentState}
 */
export const selectSurgeryAssignment = (state) => state.surgeryAssignmentList;

/**
 * A selector for the list of surgery assignments
 *
 * @param {object} state
 * @return {Array<ProcedureInfo>}
 */
export const selectSurgeryAssignmentList = (state) => state.surgeryAssignmentList.data;

/**
 * A selector for the unique list of procedure names
 *
 * @param {object} state
 * @return {Array<string>}
 */
export const selectSurgeryNameList = createSelector(selectSurgeryAssignmentList, (data) => [
    ...new Set(data.map(({procedureName}) => procedureName))
]);

/**
 * A selector for the list of all practitioners
 *
 * @param {object} state
 * @return {Array<string>}
 */
export const selectOperatorIdsList = (state) => {
    const allIds = state.surgeryAssignmentList.data
        .map(({assignedPractitioners}) => assignedPractitioners.map(({practitioners}) => practitioners))
        .flat(2);
    const idsNoDuplicates = [...new Set(allIds)];
    return idsNoDuplicates;
};

/**
 * A selector to get the current number of assignments without practitioners
 *
 * @param {object} state
 * @return {number} The number of warnings
 */
export const selectSurgeryAssignmentWarningCount = (state) => {
    return state.surgeryAssignmentList.warningCount;
};

/**
 * A selector to get whether there is an error on save
 *
 * @param {object} state
 * @return {Array<SurgeryAssignmentPatchError>}
 */
export const selectSurgeryAssignmentErrors = (state) => selectSurgeryAssignment(state).patchErrors;

/**
 * A selector to get whether there is an error on save
 *
 * @param {object} state
 * @return {Status}
 */
export const selectSurgeryAssignmentModifyStatus = (state) => selectSurgeryAssignment(state).modifyStatus;

export const selectSurgeonExperience = ({practitionerId}) =>
    createSelector(selectSurgeryAssignment, (surgeryAssignmentState) => {
        if (!practitionerId) return [];
        return surgeryAssignmentState.practitionerExperience.filter(
            (practitionerExperienceItem) => practitionerExperienceItem.practitionerId === practitionerId
        );
    });

/**
 * A selector to get status for fetch surgeon experience
 *
 * @param {object} state
 * @return {Status}
 */
export const selectSurgeonExperienceStatus = (state) => selectSurgeryAssignment(state).loadingExperienceStatus;

/**
 * A selector to get status for fetch surgeon experience
 *
 * @param {object} state
 * @return {Status}
 */
export const selectIsSurgeonSelectorOpen = (state) => selectSurgeryAssignment(state).isSurgeonSelectorOpen;

/**
 * A selector for the surgeon options
 *
 * @param {object} state
 * @return {Object<string, string>} // key: practitionerId, value: pracroleId
 */
export const selectSurgeonOptions = (state) => selectSurgeryAssignment(state).surgeonOptions;

/**
 * A selector for the surgeonids for all disciplines from the surgeonOptions
 *
 * @return {Array<string>}
 */
export const selectSurgeonIdOptionsList = createSelector(selectSurgeonOptions, (surgeonOptions) => Object.keys(surgeonOptions));

export const selectSurgeonIdOptionsForDisciplineList = ({hcServiceId}) =>
    createSelector([selectSurgeonOptions], (surgeonOptions) =>
        Object.entries(surgeonOptions)
            .filter(([_, surgeonOptionsItem]) => surgeonOptionsItem.hcServiceIds?.includes(hcServiceId))
            .map(([practitionerId, _]) => practitionerId)
    );
