// @ts-check
import {authUserFailureAction} from "../../redux/actions/index";
import ActionTypes from "./timeslots_action_types";
import {checkTimeslots, deleteTimeslot, fetchAllTimeslots, saveTimeslots} from "./timeslots_api";

const loadTimeslotsRequestAction = () => ({
    type: ActionTypes.LOAD_REQUEST
});

const loadTimeslotsSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SUCCESS,
    payload
});

const loadTimeslotsFailureAction = (error) => ({
    type: ActionTypes.LOAD_FAILURE,
    error
});

/**
 * load timeslots
 * @param {string} organizationId
 * @param {string} selectedDate in ISO8061 format ex. 2020-04-23
 * @return {AnyAction}
 */
function loadTimeslotsAction(organizationId, selectedDate) {
    return function (dispatch) {
        dispatch(loadTimeslotsRequestAction());

        fetchAllTimeslots(organizationId, selectedDate)
            .then(({data}) => {
                dispatch(loadTimeslotsSuccessAction(data.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch timeslots error"}));
                } else {
                    dispatch(loadTimeslotsFailureAction(error));
                }
            });
    };
}

/**
 * save y-axis labels
 * @param {object} payload array of y-axis labels
 * @return {{type: string, payload: *}}
 */
function saveOpRooms(payload) {
    return {type: ActionTypes.SAVE_OP_ROOMS, payload};
}

/**
 * save core values
 * @param {object} payload
 * @return {{type: string, payload: *}}
 */
function saveCoreValues(payload) {
    return {type: ActionTypes.SAVE_CORE_VALUES, payload};
}

const checkTimeslotsRequestAction = () => ({
    type: ActionTypes.CHECK_REQUEST
});

const checkTimeslotsSuccessAction = (payload) => ({
    type: ActionTypes.CHECK_SUCCESS,
    payload
});

const checkTimeslotsFailureAction = (error) => ({
    type: ActionTypes.CHECK_FAILURE,
    error
});

/**
 * Checks timeslots in the BE to see if there are no conflicts (ex. time, date, location).
 * In case there are no conflicts the data is saved in the BE and in the redux store
 *
 * @param {object} dataToSave The timeslot data.
 * @param {string} selectedDate The selected date for the current timeslot.
 * @param {boolean} [skipSave=false] if true, the save action is not called
 * @return {function}
 */
function checkAndSaveTimeslotsAction(dataToSave, selectedDate, skipSave = false) {
    return function (dispatch) {
        dispatch(checkTimeslotsRequestAction());

        checkTimeslots(dataToSave)
            .then(({data}) => {
                dispatch(checkTimeslotsSuccessAction(data.data));
                // If there are no conflicts save in the BE and the redux store
                if (!skipSave && data.data.conflicts?.length === 0 && data.data.totalCounts !== 0) {
                    const dataModified = {...dataToSave, locations: [dataToSave.location]};
                    delete dataModified.location;
                    dispatch(saveTimeslotsAction(dataModified, selectedDate));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "check timeslot error"}));
                } else {
                    dispatch(checkTimeslotsFailureAction(error));
                }
            });
    };
}

const saveTimeslotsRequestAction = () => ({
    type: ActionTypes.SAVE_REQUEST
});

const saveTimeslotsSuccessAction = (payload) => ({
    type: ActionTypes.SAVE_SUCCESS,
    payload
});

const saveTimeslotsFailureAction = (error) => ({
    type: ActionTypes.SAVE_FAILURE,
    error
});

/**
 * save timeslots
 * @param {object} data
 * @param {object} selectedDate
 * @return {function}
 */
function saveTimeslotsAction(data, selectedDate) {
    return function (dispatch) {
        dispatch(saveTimeslotsRequestAction());

        saveTimeslots(data)
            .then((result) => {
                dispatch(saveTimeslotsSuccessAction());
                dispatch(loadTimeslotsAction(data.organizationId, selectedDate));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "save timeslot error"}));
                } else {
                    dispatch(saveTimeslotsFailureAction(error));
                }
            });
    };
}

/**
 * clear conflicts and preCalcDates of the slot
 * @return {{type: string}}
 */
function clearPreCheck() {
    return {type: ActionTypes.CLEAR_CHECK};
}

const deleteTimeslotRequestAction = () => ({
    type: ActionTypes.DELETE_REQUEST
});

const deleteTimeslotSuccessAction = (payload) => ({
    type: ActionTypes.DELETE_SUCCESS,
    payload
});

const deleteTimeslotFailureAction = (error) => ({
    type: ActionTypes.DELETE_FAILURE,
    error
});

/**
 * delete timeslots
 * @param {object} data
 * @param {string} organizationId
 * @param {object} selectedDate
 * @return {function}
 */
function deleteTimeslotAction(data, organizationId, selectedDate) {
    return function (dispatch) {
        dispatch(deleteTimeslotRequestAction());

        deleteTimeslot(data)
            .then(() => {
                dispatch(deleteTimeslotSuccessAction());
                dispatch(loadTimeslotsAction(organizationId, selectedDate));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "delete timeslot error"}));
                } else {
                    dispatch(deleteTimeslotFailureAction(error));
                }
            });
    };
}

/**
 * Selected date for the main view
 * @param {DateTimeType} payload DateTime object
 * @return {{type: string, payload: *}}
 */
function changeDate(payload) {
    return {type: ActionTypes.CHANGE_DATE, payload};
}

export {loadTimeslotsAction, saveOpRooms, saveCoreValues, checkAndSaveTimeslotsAction, clearPreCheck, deleteTimeslotAction, changeDate};
