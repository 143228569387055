const ActionTypes = {
    SAVE_USER_OBJECT: "app/SAVE_USER_OBJECT",

    AUTH_USER_REQUEST: "app/AUTH_USER_REQUEST",
    AUTH_USER_SUCCESS: "app/AUTH_USER_SUCCESS",
    AUTH_USER_FAILURE: "app/AUTH_USER_FAILURE",
    AUTH_USER_RECOVER: "app/AUTH_USER_RECOVER",

    GET_TIMEZONE: "app/GET_TIMEZONE",

    TOGGLE_MAIN_MENU: "app/TOGGLE_MAIN_MENU",
    SET_LANGUAGE: "app/SET_LANGUAGE",

    TOGGLE_SLOT_VIEW: "app/TOGGLE_SLOT_VIEW",

    USER_STRATEGY_REQUEST: "app/USER_STRATEGY_REQUEST",
    USER_STRATEGY_SUCCESS: "app/USER_STRATEGY_SUCCESS",
    USER_STRATEGY_FAILURE: "app/USER_STRATEGY_FAILURE",

    SET_REFRESH_TRIGGER: "app/SET_REFRESH_TRIGGER",

    LOGOUT_USER_REQUEST: "app/LOGOUT_USER_REQUEST",
    LOGOUT_USER_SUCCESS: "app/LOGOUT_USER_SUCCESS",
    LOGOUT_USER_FAILURE: "app/LOGOUT_USER_FAILURE",

    FETCH_SERVICE_VERSION: "app/FETCH_SERVICE_VERSION"
};

export default ActionTypes;
