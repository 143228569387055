// @ts-check
import logger from "../../utils/logger_pino";
import {fetchPrintDates, patchPrintDates} from "../apis/print_api";
import {selectCurrentUserEmail} from "../app_selectors";
import {authUserFailureAction} from ".";
import ActionTypes from "./print_action_types";

const loadPrintDatesRequestAction = () => ({
    type: ActionTypes.LOAD_PRINT_DATES_REQUEST
});

const loadPrintDatesSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_PRINT_DATES_SUCCESS,
    payload
});

const loadPrintDatesFailureAction = () => ({
    type: ActionTypes.LOAD_PRINT_DATES_FAILURE
});

/**
 * load allowed dates for print
 * @param {string} organizationId
 * @return {AnyAction}
 */
function loadPrintDatesAction(organizationId) {
    return function (dispatch, getState) {
        dispatch(loadPrintDatesRequestAction());
        const email = selectCurrentUserEmail(getState());
        fetchPrintDates({organizationId})
            .then(({data}) => {
                dispatch(loadPrintDatesSuccessAction(data?.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch print-dates error"}));
                } else {
                    logger.warn("load print-dates faild", {email, organizationId, error});
                    dispatch(loadPrintDatesFailureAction());
                }
            });
    };
}

const savePrintDatesRequestAction = () => ({
    type: ActionTypes.SAVE_PRINT_DATES_REQUEST
});

const savePrintDatesSuccessAction = () => ({
    type: ActionTypes.SAVE_PRINT_DATES_SUCCESS
});

const savePrintDatesFailureAction = () => ({
    type: ActionTypes.SAVE_PRINT_DATES_FAILURE
});

/**
 * load allowed dates for print
 * @param {string} organizationId
 * @param {Array<{date: string, note: string}>} dates array of object. example {date: "2023-02-23", note: "abc"}
 * @return {function}
 */
function savePrintDatesAction(organizationId, dates) {
    return function (dispatch, getState) {
        dispatch(savePrintDatesRequestAction());
        const email = selectCurrentUserEmail(getState());
        patchPrintDates({organizationId, dates})
            .then(() => {
                dispatch(savePrintDatesSuccessAction());
                dispatch(loadPrintDatesAction(organizationId));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "patch print-dates error"}));
                } else {
                    logger.warn("save print-dates faild", {email, organizationId, error});
                    dispatch(savePrintDatesFailureAction());
                }
            });
    };
}
const resetStatusPatchPrintDatesAction = () => ({
    type: ActionTypes.RESET_SAVE_PRINT_DATES_STATUS
});

export {loadPrintDatesAction, savePrintDatesAction, resetStatusPatchPrintDatesAction};
