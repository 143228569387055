// @ts-check
import {clearLocalStorage} from "../../utils/local_storage";
import logger from "../../utils/logger_pino";
import {authUserObject, fetchServiceVersionApi, getTimezone, getUserLDAPorLocalStrategy, logoutApi} from "../apis/app_api";
import ActionTypes from "./app_action_types";

/** @typedef {import("redux").Dispatch} Dispatch */
/**
 * save userObject
 * @param {object} userObject
 * @return {{type: string, userObject: UserObjectStore}}
 */
export function saveUserObject(userObject) {
    return {type: ActionTypes.SAVE_USER_OBJECT, userObject};
}

const authUserRequestAction = () => ({
    type: ActionTypes.AUTH_USER_REQUEST
});

const authUserSuccessAction = (userObject) => ({
    type: ActionTypes.AUTH_USER_SUCCESS,
    userObject
});

export const authUserFailureAction = (authError) => ({
    type: ActionTypes.AUTH_USER_FAILURE,
    authError
});

export const authUserRecoverAction = () => ({
    type: ActionTypes.AUTH_USER_RECOVER
});

/**
 * auth User
 *
 * @return {function}
 */
export function authUser() {
    return function (dispatch) {
        dispatch(authUserRequestAction());
        authUserObject()
            .then(({data: {data}}) => {
                const userObject = data;
                if (!userObject.active) {
                    dispatch(authUserFailureAction({error: true, message: "deactivated"}));
                }
                getTimezone(userObject.organizationId)
                    .then(
                        ({
                            data: {
                                data: {timezone}
                            }
                        }) => {
                            const userObjectNew = {...userObject, timezone};
                            dispatch(authUserSuccessAction(userObjectNew));
                        }
                    )
                    .catch((e) => {
                        // @TODO #14812: define how to handle if the timezone of organization is not found
                        logger.warn(`could not get timezone of ${userObject.organizationId}.`);
                        const userObjectNew = {...userObject, timezone: "Europe/Berlin"};
                        dispatch(authUserSuccessAction(userObjectNew));
                    });
            })
            .catch(() => {
                logger.warn("User authentication error");
                dispatch(authUserFailureAction({error: true, message: "user_object error"}));
            });
    };
}

/**
 * toggle main menu
 * @return {{type: string}}
 */
export function toggleMainMenu() {
    return {type: ActionTypes.TOGGLE_MAIN_MENU};
}

/**
 * set language
 * @param {String} language
 * @return {{type: string, language: string}}
 */
export function setLanguageAction(language) {
    return {type: ActionTypes.SET_LANGUAGE, language};
}

/**
 * toggle slot view (days: true, list: false)
 * @return {{type: string}}
 */
export function toggleSlotViewAction() {
    return {type: ActionTypes.TOGGLE_SLOT_VIEW};
}

export const userStrategyRequestAction = () => ({
    type: ActionTypes.USER_STRATEGY_REQUEST
});

/**
 *
 * @param {UserStrategy} userStrategy
 * @return {{type: string, userStrategy: UserStrategy }}
 */
const userStrategySuccessAction = (userStrategy) => ({
    type: ActionTypes.USER_STRATEGY_SUCCESS,
    userStrategy
});

export const userStrategyFailureAction = (error) => ({
    type: ActionTypes.USER_STRATEGY_FAILURE,
    error
});

/**
 * Get the user strategy (LDAP or Local)
 *
 * @return {function}
 */
export const getUserStrategy = () => (/** @type {Dispatch}} */ dispatch) => {
    dispatch(userStrategyRequestAction());
    getUserLDAPorLocalStrategy()
        .then(({data: {data}}) => {
            dispatch(userStrategySuccessAction(data));
        })
        .catch(() => {
            dispatch(userStrategyFailureAction({error: true, message: "fetch userStrategy error"}));
        });
};

/**
 * set refresh trigger
 * @return {{type: string}}
 */
export function setRefreshTrigger() {
    return {type: ActionTypes.SET_REFRESH_TRIGGER};
}

const logoutRequestAction = () => ({type: ActionTypes.LOGOUT_USER_REQUEST});

const logoutSuccessAction = () => ({type: ActionTypes.LOGOUT_USER_SUCCESS});

const logoutFailureAction = (error) => ({
    type: ActionTypes.LOGOUT_USER_FAILURE,
    error
});

/** @typedef {import("react-oidc-context").AuthContextProps} AuthContextProps */

/**
 * Logout action
 * @param {string} refresh_token
 * @return {function}
 */
export const logoutAction = (refresh_token) => (/** @type {Dispatch}} */ dispatch) => {
    dispatch(logoutRequestAction());
    clearLocalStorage();
    logoutApi(refresh_token)
        .then(() => {
            dispatch(logoutSuccessAction());
        })
        .catch((err) => {
            dispatch(logoutFailureAction(err));
        });
};

const fetchServiceVersionSuccess = (version) => ({type: ActionTypes.FETCH_SERVICE_VERSION, version});

/**
 * fetch service version action
 * @return {function}
 */
export const fetchServiceVersionAction = () => (/** @type {Dispatch}} */ dispatch) => {
    fetchServiceVersionApi()
        .then(({data}) => {
            dispatch(fetchServiceVersionSuccess(data?.version));
        })
        .catch((err) => {
            logger.warn("fetchServiceVersion failed");
        });
};
