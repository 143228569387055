import ActionTypes from "./room_planner_action_types";

/**
 * change date period
 * @param {DateTimeType} date - the new start date
 * @param {number} interval - the interval for end date
 * @return {Object}
 */
const changeDate = (date, interval) => ({
    type: ActionTypes.CHANGE_DATE,
    date,
    interval
});

const reloadRoomPlannerAction = (reload) => ({
    type: ActionTypes.RELOAD_ROOM_PLANNER,
    reload
});

export {changeDate, reloadRoomPlannerAction};
