import {createSelector} from "reselect";

import {plusDT} from "../../utils/luxon_helpers";

const selectRoomPlanner = (state) => state.roomPlannerPage;

const selectRoomPlannerInterval = (state) => selectRoomPlanner(state).interval;
const selectRoomPlannerDateFrom = (state) => selectRoomPlanner(state).date;
const selectRoomPlannerDateTo = createSelector(selectRoomPlannerDateFrom, selectRoomPlannerInterval, (date, interval) =>
    date ? plusDT(date, "day", interval - 1) : null
);
const selectReload = (state) => selectRoomPlanner(state).reload;

export {selectRoomPlannerInterval, selectRoomPlannerDateFrom, selectRoomPlannerDateTo, selectReload};
