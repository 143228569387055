// @ts-check
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import Legend from "../../components/legend/legend";
import {loadBlockedRoomsAction} from "../../components/room_planner/room_planner_actions";
import RoomsFilter from "../../components/rooms/components/rooms_filter/rooms_filter";
import ActionMenubar from "../../components/shared/action_menubar/action_menubar";
import DatePicker from "../../components/shared/date_picker/date_picker";
import DetailRight from "../../components/shared/detail_right/detail_right";
import Loading from "../../components/shared/loading/loading";
import Message from "../../components/shared/message/message";
import Page from "../../components/shared/page";
import ViewSwitch from "../../components/shared/view_switch/view_switch";
import TimeslotCanvas from "../../components/timeslot_canvas/timeslot_canvas";
import TimeslotDialog from "../../components/timeslot_dialog/timeslot_dialog";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import usePrevious from "../../hooks/usePrevious";
import {toggleSlotViewAction} from "../../redux/actions";
import {selectCurrentOrganizationId, selectCurrentTimezone, selectSlotViewDays} from "../../redux/app_selectors";
import {isIdle, isPending, isRejected, isResolved} from "../../redux/utils/status";
import {PERMISSION, useSecurity} from "../../utils/security";
import TimeslotTablePage from "../timeslot_table/timeslot_table_page";
import {changeDate, clearPreCheck, loadTimeslotsAction, saveCoreValues, saveOpRooms} from "./timeslots_actions";
import {
    selectCoreValues,
    selectDeleteStatus,
    selectLoadStatus,
    selectOpRoomIds,
    selectSaveStatus,
    selectSelectedDate
} from "./timeslots_selectors";
import {getActionItems} from "./utils/timeslots_action_items";

/**
 * render TimeslotsPage component
 * @return {React.ReactElement}
 */
const TimeslotsPage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isGranted} = useSecurity();
    const {now, format} = useContext(DateContext);

    // Redux
    const selectedDate = useSelector(selectSelectedDate);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const loadStatus = useSelector(selectLoadStatus);
    const opRooms = useSelector(selectOpRoomIds);
    const timezone = useSelector(selectCurrentTimezone);
    const coreValues = useSelector(selectCoreValues);
    const saveStatus = useSelector(selectSaveStatus);
    const deleteStatus = useSelector(selectDeleteStatus);
    const slotViewDays = useSelector(selectSlotViewDays);

    // State
    const [openChange, setOpenChange] = useState(false);
    const [openLegend, setOpenLegend] = useState(false);
    const [openRooms, setOpenRooms] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [showFullActionMenubar, setShowFullActionMenubar] = useState(false);

    const prevSaveStatus = usePrevious(saveStatus);
    const prevDeleteStatus = usePrevious(deleteStatus);

    useEffect(() => {
        dispatch(changeDate(now()));
    }, []);

    useEffect(() => {
        if (selectedDate && organizationId && timezone) {
            const selectedDateFormatted = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
            dispatch(loadTimeslotsAction(organizationId, selectedDateFormatted));
            dispatch(loadBlockedRoomsAction(organizationId, selectedDateFormatted, selectedDateFormatted));
        }
    }, [selectedDate, organizationId, timezone]);

    useEffect(() => {
        // handle error while saving changes
        if (isPending(prevSaveStatus) && isRejected(saveStatus)) {
            setError(t("TimeslotsPage.saveError"));
            setTimeout(() => {
                setError(null);
            }, 5000);
        }
    }, [prevSaveStatus, saveStatus]);

    useEffect(() => {
        // handle error while deleting
        if (isPending(prevDeleteStatus) && isRejected(deleteStatus)) {
            setError(t("TimeslotsPage.deleteError"));
            setTimeout(() => {
                setError(null);
            }, 5000);
        }
    }, [prevDeleteStatus, deleteStatus]);

    useEffect(() => {
        if (prevSaveStatus && saveStatus !== prevSaveStatus && isResolved(saveStatus)) {
            setMessage(t("TimeslotsPage.saveSuccess"));
            setTimeout(() => {
                setMessage(null);
            }, 5000);

            handleCloseChange();
            dispatch(clearPreCheck());
            setSelectedSlot(null);
        }
    }, [saveStatus]);

    useEffect(() => {
        if (prevDeleteStatus && deleteStatus !== prevDeleteStatus && isResolved(deleteStatus)) {
            setMessage(t("TimeslotsPage.deleteSuccess"));
            setTimeout(() => {
                setMessage(null);
            }, 5000);

            handleCloseChange();
            dispatch(clearPreCheck());
            setSelectedSlot(null);
        }
    }, [deleteStatus]);

    // handlers for layer open
    const handleOpenChange = () => {
        if (!openChange) {
            setOpenChange(true);
            setOpenRooms(false);
            setOpenLegend(false);
        } else {
            dispatch(clearPreCheck());
            setOpenChange(false);
            setSelectedSlot(null);
        }
    };
    const handleOpenLegend = () => {
        if (!openLegend) {
            setOpenChange(false);
            setOpenRooms(false);
            setOpenLegend(true);
        } else {
            setOpenLegend(false);
        }
    };
    const handleOpenRooms = () => {
        if (!openRooms) {
            setOpenChange(false);
            setOpenRooms(true);
            setOpenLegend(false);
        } else {
            setOpenRooms(false);
        }
    };

    // handlers for layer close
    const handleCloseChange = () => {
        setOpenChange(false);
        dispatch(clearPreCheck());
        setSelectedSlot(null);
    };
    const handleCloseLegend = (e) => {
        setOpenLegend(false);
    };
    const handleCloseRooms = () => {
        setOpenRooms(false);
    };
    const handleChangeDate = (date) => {
        dispatch(changeDate(date));
    };
    const renderHeaderOptions = () => (
        <DatePicker changeDate={handleChangeDate} loading={isPending(loadStatus)} selectedDate={selectedDate} />
    );

    // Set centered action items
    const handlers = {
        handleOpenChange: handleOpenChange,
        handleOpenLegend: handleOpenLegend,
        handleOpenRooms: handleOpenRooms
    };
    const isOpened = {
        openChange,
        openLegend,
        openRooms
    };
    const actions = isGranted(PERMISSION.MODIFY_HCSERVICE) ? getActionItems(handlers, isOpened) : [];

    const handleSaveRoomsFilter = ({opRooms}) => {
        const coreValuesNew = {...coreValues};
        coreValuesNew.rowCount = opRooms.length;

        dispatch(saveCoreValues(coreValuesNew));
        dispatch(saveOpRooms(opRooms));
    };

    /**
     *
     * @param {TimeSlot} slot
     */
    const handleClick = (slot) => {
        setSelectedSlot(slot);
        handleOpenChange();
    };
    const toggleSlotView = () => {
        dispatch(toggleSlotViewAction());
    };

    const handleToggleActionMenubarWidth = () => {
        setShowFullActionMenubar(!showFullActionMenubar);
    };

    const headerItems = [
        <ViewSwitch
            isChecked={!slotViewDays}
            key="view-switch"
            labels={{left: t("App.days"), right: t("App.list")}}
            title={t("App.dayView")}
            onToggle={toggleSlotView}
        />
    ];
    if (!slotViewDays) {
        return <TimeslotTablePage />;
    }
    return (
        <Page
            fullActionMenubar={showFullActionMenubar}
            fullCanvas
            headerItems={headerItems}
            name="timeslotPage"
            openRightLayer={openChange || openLegend || openRooms}
            organizationId={organizationId}
            renderOptions={renderHeaderOptions}
            title={t("TimeslotsPage.title")}
        >
            {isIdle(loadStatus) && <Loading />}
            {isRejected(loadStatus) && <Message message={t("TimeslotsPage.loadError")} severity="error" />}
            {message && <Message message={message} />}
            {error && <Message message={error} severity="error" />}
            {organizationId && isResolved(loadStatus) && <TimeslotCanvas onClick={handleClick} />}
            {
                <ActionMenubar
                    actions={actions}
                    showFullActionMenubar={showFullActionMenubar}
                    onToggleWidth={handleToggleActionMenubarWidth}
                />
            }
            {openChange && (
                <DetailRight fullActionMenubar={showFullActionMenubar} open={openChange} onClose={handleCloseChange}>
                    <TimeslotDialog timeslot={selectedSlot} />
                </DetailRight>
            )}
            {openLegend && (
                <DetailRight fullActionMenubar={showFullActionMenubar} open={openLegend} onClose={handleCloseLegend}>
                    <Legend />
                </DetailRight>
            )}
            {openRooms && (
                <DetailRight fullActionMenubar={showFullActionMenubar} open={openRooms} onClose={handleCloseRooms}>
                    <RoomsFilter opRooms={opRooms} onSave={handleSaveRoomsFilter} />
                </DetailRight>
            )}
        </Page>
    );
};

export default TimeslotsPage;
