import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./timeslots_action_types";

const initialState = {
    data: [],
    loadStatus: STATUS.IDLE,
    error: null,
    opRooms: [],
    coreValues: {
        canvasStart: 0, // default
        canvasEnd: 23, // default
        rowCount: 0,
        widthPerHour: 120,
        rowHeight: 80, // default
        roomsFilter: []
    },
    saveStatus: STATUS.IDLE,
    saveError: null,
    checkStatus: STATUS.IDLE,
    checkError: null,
    preCheck: {},
    deleteStatus: STATUS.IDLE,
    selectedDate: null
};

const handleLoadRequest = (state) => ({
    ...state,
    loadStatus: STATUS.PENDING,
    error: null
});

const handleLoadFailure = (state, {error}) => ({
    ...state,
    loadStatus: STATUS.REJECTED,
    error
});

const handleLoadSuccess = (state, {payload}) => ({
    ...state,
    loadStatus: STATUS.RESOLVED,
    error: null,
    data: payload
});

const handleSaveOpRooms = (state, {payload}) => ({
    ...state,
    opRooms: payload
});

const handleSaveCoreValues = (state, {payload}) => ({
    ...state,
    coreValues: {...payload}
});

const handleCheckRequest = (state) => ({
    ...state,
    checkStatus: STATUS.PENDING,
    error: null
});

const handleCheckFailure = (state, {error}) => ({
    ...state,
    checkStatus: STATUS.REJECTED,
    error
});

const handleCheckSuccess = (state, {payload}) => ({
    ...state,
    checkStatus: STATUS.RESOLVED,
    error: null,
    preCheck: payload
});

const handleSaveRequest = (state) => ({
    ...state,
    saveStatus: STATUS.PENDING,
    error: null
});

const handleSaveFailure = (state, {error}) => ({
    ...state,
    saveStatus: STATUS.REJECTED,
    error
});

const handleSaveSuccess = (state) => ({
    ...state,
    saveStatus: STATUS.RESOLVED,
    error: null
});

const handleClearPreCheck = (state) => ({
    ...state,
    preCheck: {
        conflicts: [],
        preCalcDates: []
    }
});
const handleDeleteRequest = (state) => ({
    ...state,
    deleteStatus: STATUS.PENDING,
    error: null
});
const handleDeleteFailure = (state, {error}) => ({
    ...state,
    deleteStatus: STATUS.REJECTED,
    error
});

const handleDeleteSuccess = (state) => ({
    ...state,
    deleteStatus: STATUS.RESOLVED,
    error: null
});

const handleChangeDate = (state, {payload}) => ({
    ...state,
    selectedDate: payload
});

const handlers = {
    [ActionTypes.LOAD_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_FAILURE]: handleLoadFailure,
    [ActionTypes.SAVE_OP_ROOMS]: handleSaveOpRooms,
    [ActionTypes.SAVE_CORE_VALUES]: handleSaveCoreValues,
    [ActionTypes.CHECK_REQUEST]: handleCheckRequest,
    [ActionTypes.CHECK_SUCCESS]: handleCheckSuccess,
    [ActionTypes.CHECK_FAILURE]: handleCheckFailure,
    [ActionTypes.SAVE_REQUEST]: handleSaveRequest,
    [ActionTypes.SAVE_SUCCESS]: handleSaveSuccess,
    [ActionTypes.SAVE_FAILURE]: handleSaveFailure,
    [ActionTypes.CLEAR_CHECK]: handleClearPreCheck,
    [ActionTypes.DELETE_REQUEST]: handleDeleteRequest,
    [ActionTypes.DELETE_SUCCESS]: handleDeleteSuccess,
    [ActionTypes.DELETE_FAILURE]: handleDeleteFailure,
    [ActionTypes.CHANGE_DATE]: handleChangeDate
};
export default createReducer(initialState, handlers);
