import {array, arrayOf, bool, number, oneOf, shape, string} from "prop-types";

const timeslots = shape({
    organizationId: string,
    locations: array.isRequired,
    healthcareServices: arrayOf(string),
    startDate: string.isRequired, // date format
    startTime: string, // date format
    endDate: string, // date format
    endTime: string, // date format
    interval: oneOf(["none", "daily", "weekdays", "weekly", "monthly", "yearly", "user-defined"]),
    frequency: arrayOf(number), // only set if interval=user-defined
    hourStartLocalTime: number,
    hourEndLocalTime: number,
    repeatAfterDays: number, // only set if interval=user-defined
    email: string,
    _id: string, // only set in case of modifying a existing slot
    _hasPractitioners: bool,
    preCalcDates: arrayOf(shape({start: string, end: string})),
    preIdsSubstitute: arrayOf(string),
    pracIdsPreferred: arrayOf(string)
});

export {timeslots};
