const ActionTypes = {
    LOAD_REQUEST: "timeslots/LOAD_TIMESLOT_REQUEST",
    LOAD_SUCCESS: "timeslots/LOAD_TIMESLOT_SUCCESS",
    LOAD_FAILURE: "timeslots/LOAD_TIMESLOT_FAILURE",
    SAVE_OP_ROOMS: "timeslots/SAVE_OP_ROOMS",
    SAVE_CORE_VALUES: "timeslots/SAVE_CORE_VALUES",
    CHECK_REQUEST: "timeslots/CHECK_TIMESLOT_REQUEST",
    CHECK_SUCCESS: "timeslots/CHECK_TIMESLOT_SUCCESS",
    CHECK_FAILURE: "timeslots/CHECK_TIMESLOT_FAILURE",
    SAVE_REQUEST: "timeslots/SAVE_TIMESLOT_REQUEST",
    SAVE_SUCCESS: "timeslots/SAVE_TIMESLOT_SUCCESS",
    SAVE_FAILURE: "timeslots/SAVE_TIMESLOT_FAILURE",
    CLEAR_CHECK: "timeslots/CLEAR_TIMESLOT",
    DELETE_REQUEST: "timeslots/DELETE_TIMESLOT_REQUEST",
    DELETE_SUCCESS: "timeslots/DELETE_TIMESLOT_SUCCESS",
    DELETE_FAILURE: "timeslots/DELETE_TIMESLOT_FAILURE",
    CHANGE_DATE: "timeslots/CHANGE_DATE"
};

export default ActionTypes;
