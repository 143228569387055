// @ts-check
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../config/config.json";
import ActionMenubar from "../../components/shared/action_menubar/action_menubar";
import DetailRight from "../../components/shared/detail_right/detail_right";
import Message from "../../components/shared/message/message";
import Page from "../../components/shared/page";
import ViewSwitch from "../../components/shared/view_switch/view_switch";
import TimeslotDialog from "../../components/timeslot_dialog/timeslot_dialog";
import TimeslotTableCanvas from "../../components/timeslot_table_canvas/timeslot_table_canvas";
import {DateContext} from "../../contexts/dates";
import usePrevious from "../../hooks/usePrevious";
import {toggleSlotViewAction} from "../../redux/actions";
import {selectCurrentOrganizationId, selectSlotViewDays} from "../../redux/app_selectors";
import {isRejected, isResolved} from "../../redux/utils/status";
import {PERMISSION, useSecurity} from "../../utils/security";
import {clearPreCheck} from "../timeslots/timeslots_actions";
import {selectDeleteStatus, selectSaveStatus} from "../timeslots/timeslots_selectors";
import {changeDate} from "./timeslot_table_actions";
import {selectData, selectLoadStatus} from "./timeslot_table_selectors";
import {getActionItems} from "./utils/timeslot_table_action_items";

const TimeslotTablePage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isGranted} = useSecurity();
    const {fromISO, diffDT} = useContext(DateContext);

    const [openChange, setOpenChange] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [message, setMessage] = useState(null);
    const [saved, setSaved] = useState(false);
    const [showFullActionMenubar, setShowFullActionMenubar] = useState(false);

    const organizationId = useSelector(selectCurrentOrganizationId);
    const timeslots = useSelector(selectData);
    const loadStatus = useSelector(selectLoadStatus);
    const saveStatus = useSelector(selectSaveStatus);
    const deleteStatus = useSelector(selectDeleteStatus);
    const slotViewDays = useSelector(selectSlotViewDays);

    const prevSaveStatus = usePrevious(saveStatus);
    const prevDeleteStatus = usePrevious(deleteStatus);

    useEffect(() => {
        if (timeslots.length !== 0) {
            let minDate = fromISO(config.MAX_SLOT_DATE);
            let maxDate = fromISO(config.MIN_SLOT_DATE);
            for (const slot of timeslots) {
                if (minDate > fromISO(slot.startDate)) {
                    minDate = fromISO(slot.startDate);
                }
                if (maxDate < fromISO(slot.endDate)) {
                    maxDate = fromISO(slot.endDate);
                }
            }
            dispatch(changeDate(minDate, diffDT(maxDate, minDate, "days")));
        }
    }, [timeslots]);

    useEffect(() => {
        if (prevSaveStatus && saveStatus !== prevSaveStatus && isResolved(saveStatus)) {
            setMessage(t("TimeslotsPage.saveSuccess"));
            setTimeout(() => {
                setMessage(null);
            }, 5000);

            handleCloseChange();
            dispatch(clearPreCheck());
            setSelectedSlot(null);
            setSaved(true);
        }
    }, [saveStatus]);

    useEffect(() => {
        if (prevDeleteStatus && deleteStatus !== prevDeleteStatus && isResolved(deleteStatus)) {
            setMessage(t("TimeslotsPage.deleteSuccess"));
            setTimeout(() => {
                setMessage(null);
            }, 5000);

            handleCloseChange();
            dispatch(clearPreCheck());
            setSelectedSlot(null);
            setSaved(true);
        }
    }, [deleteStatus]);

    // handlers for layer open
    const handleOpenChange = () => {
        if (!openChange) {
            setOpenChange(true);
            setSaved(false);
        } else {
            setOpenChange(false);
        }
    };

    // handlers for layer close
    const handleCloseChange = () => {
        setOpenChange(false);
        dispatch(clearPreCheck());
        setSelectedSlot(null);
    };

    // Set centered action items
    const handlersCenter = {
        handleOpenChange: handleOpenChange
    };
    const isOpened = {
        openChange
    };
    const actions = isGranted(PERMISSION.MODIFY_HCSERVICE) ? getActionItems(handlersCenter, isOpened) : [];

    const handleEdit = (slot) => {
        setSelectedSlot(slot);
        handleOpenChange();
    };

    const toggleSlotView = () => {
        dispatch(toggleSlotViewAction());
    };

    const handleToggleActionMenubarWidth = () => {
        setShowFullActionMenubar(!showFullActionMenubar);
    };

    const headerItems = [
        <ViewSwitch
            isChecked={!slotViewDays}
            key="view-switch"
            labels={{left: t("App.days"), right: t("App.list")}}
            title={t("App.listView")}
            onToggle={toggleSlotView}
        />
    ];
    return (
        <Page
            fullActionMenubar={showFullActionMenubar}
            fullCanvas
            headerItems={headerItems}
            openRightLayer={openChange}
            organizationId={organizationId}
            title={t("TimeslotTablePage.title")}
        >
            {isRejected(loadStatus) && <Message message={t("TimeslotsPage.loadError")} severity="error" />}
            {message && <Message message={message} />}
            <ActionMenubar actions={actions} showFullActionMenubar={showFullActionMenubar} onToggleWidth={handleToggleActionMenubarWidth} />
            {organizationId && (
                <TimeslotTableCanvas isLayerOpen={openChange} isSaved={saved} onAdd={handleOpenChange} onEdit={handleEdit} />
            )}
            {openChange && (
                <DetailRight fullActionMenubar={showFullActionMenubar} open={openChange} onClose={handleCloseChange}>
                    <TimeslotDialog timeslot={selectedSlot} />
                </DetailRight>
            )}
        </Page>
    );
};

export default TimeslotTablePage;
