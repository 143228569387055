// @ts-check
/**
 * @fileoverview API for timeslots service
 */

import axiosClient from "../../middleware/axios.middelware";

const URL_TIMESLOTS = "/orchestrator/hcservices/slots";

/**
 * fetch timeslots
 * @param {Object} params in ISO8061 format ex. 2020-04-23
 * @return {Promise}
 */
function fetchAllTimeslots(params) {
    return axiosClient.get(URL_TIMESLOTS, {
        params
    });
}

export {fetchAllTimeslots};
