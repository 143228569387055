// @ts-check
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import Page from "../../components/shared/page";
import {SurgeryAssignmentCanvas} from "../../components/surgery_assignment_canvas/surgery_assignment_canvas";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";

/**
 * Renders the surger assignment page on the route: /admin/surgery-assignment
 *
 * @return {React.ReactElement}
 */
export const SurgeryAssignmentPage = () => {
    const {t} = useTranslation();

    const organizationId = useSelector(selectCurrentOrganizationId);

    return (
        <Page hasNoPadding organizationId={organizationId} title={t("SurgeryAssignmentPage.title")}>
            {organizationId ? <SurgeryAssignmentCanvas /> : <div></div>}
        </Page>
    );
};
