import {authUserFailureAction} from "../../redux/actions/index";
import ActionTypes from "./timeslot_table_action_types";
import {fetchAllTimeslots} from "./timeslot_table_api";

const loadTimeslotsRequestAction = () => ({
    type: ActionTypes.LOAD_REQUEST
});

const loadTimeslotsSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SUCCESS,
    payload
});

const loadTimeslotsFailureAction = (error) => ({
    type: ActionTypes.LOAD_FAILURE,
    error
});

/**
 * load timeslots
 * @param {Object} params
 * @param {number} organizationId
 * @return {AnyAction}
 */
function loadTimeslotsAction(params) {
    return function (dispatch) {
        dispatch(loadTimeslotsRequestAction());

        fetchAllTimeslots(params)
            .then(({data}) => {
                dispatch(loadTimeslotsSuccessAction(data.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch timeslots error"}));
                } else {
                    dispatch(loadTimeslotsFailureAction(error));
                }
            });
    };
}

/**
 * Selected date for the main view
 * @param {DateTimeType} date    the new start date
 * @param {number} interval    interval for end date
 * @return {{type: String, date: DateTimeType, interval: number}}
 */
function changeDate(date, interval) {
    return {type: ActionTypes.CHANGE_DATE, date, interval};
}

export {loadTimeslotsAction, changeDate};
