// @ts-check
/**
 * @typedef {("surgery_assignment/LOAD_SURGERY_ASSIGNMENT_REQUEST"|"surgery_assignment/LOAD_SURGERY_ASSIGNMENT_SUCCESS"|"surgery_assignment/LOAD_SURGERY_ASSIGNMENT_FAILURE"|"surgery_assignment/MODIFY_SURGERY_ASSIGNMENT_REQUEST"|"surgery_assignment/MODIFY_SURGERY_ASSIGNMENT_SUCCESS"|"surgery_assignment/MODIFY_SURGERY_ASSIGNMENT_FAILURE"|"surgery_assignment/SURGERY_ASSIGNMENT_HAS_WARNINGS"|"surgery_assignment/SET_IS_SURGEON_SELECTOR_OPEN")} SurgeryAssignmentActionTypes
 */

/**
 * ActionTypes definition
 *
 * @readonly
 * @link https://stackoverflow.com/questions/36488644/how-to-use-jsdoc3-to-document-enum-constants
 * @enum {String}
 * @property {"surgery_assignment/LOAD_SURGERY_ASSIGNMENT_REQUEST"} LOAD_SURGERY_ASSIGNMENT_REQUEST
 * @property {"surgery_assignment/LOAD_SURGERY_ASSIGNMENT_SUCCESS"} LOAD_SURGERY_ASSIGNMENT_SUCCESS
 * @property {"surgery_assignment/LOAD_SURGERY_ASSIGNMENT_FAILURE"} LOAD_SURGERY_ASSIGNMENT_FAILURE
 * @property {"surgery_assignment/SURGERY_ASSIGNMENT_HAS_WARNINGS"} SURGERY_ASSIGNMENT_HAS_WARNINGS
 * @property {"surgery_assignment/MODIFY_SURGERY_ASSIGNMENT_REQUEST"} MODIFY_SURGERY_ASSIGNMENT_REQUEST
 * @property {"surgery_assignment/MODIFY_SURGERY_ASSIGNMENT_SUCCESS"} MODIFY_SURGERY_ASSIGNMENT_SUCCESS
 * @property {"surgery_assignment/MODIFY_SURGERY_ASSIGNMENT_FAILURE"} MODIFY_SURGERY_ASSIGNMENT_FAILURE
 * @property {"surgery_assignment/LOAD_SURGEON_EXPERIENCE_REQUEST"} LOAD_SURGEON_EXPERIENCE_REQUEST
 * @property {"surgery_assignment/LOAD_SURGEON_EXPERIENCE_SUCCESS"} LOAD_SURGEON_EXPERIENCE_SUCCESS
 * @property {"surgery_assignment/LOAD_SURGEON_EXPERIENCE_FAILURE"} LOAD_SURGEON_EXPERIENCE_FAILURE
 * @property {"surgery_assignment/SET_IS_SURGEON_SELECTOR_OPEN"} SET_IS_SURGEON_SELECTOR_OPEN
 * @property {"surgery_assignment/LOAD_SURGEON_OPTIONS_REQUEST"} LOAD_SURGEON_OPTIONS_REQUEST
 * @property {"surgery_assignment/LOAD_SURGEON_OPTIONS_SUCCESS"} LOAD_SURGEON_OPTIONS_SUCCESS
 * @property {"surgery_assignment/LOAD_SURGEON_OPTIONS_FAILURE"} LOAD_SURGEON_OPTIONS_FAILURE
 */
export const ActionTypes = {
    LOAD_SURGERY_ASSIGNMENT_REQUEST: "surgery_assignment/LOAD_SURGERY_ASSIGNMENT_REQUEST",
    LOAD_SURGERY_ASSIGNMENT_SUCCESS: "surgery_assignment/LOAD_SURGERY_ASSIGNMENT_SUCCESS",
    LOAD_SURGERY_ASSIGNMENT_FAILURE: "surgery_assignment/LOAD_SURGERY_ASSIGNMENT_FAILURE",
    SURGERY_ASSIGNMENT_HAS_WARNINGS: "surgery_assignment/SURGERY_ASSIGNMENT_HAS_WARNINGS",
    MODIFY_SURGERY_ASSIGNMENT_REQUEST: "surgery_assignment/MODIFY_SURGERY_ASSIGNMENT_REQUEST",
    MODIFY_SURGERY_ASSIGNMENT_SUCCESS: "surgery_assignment/MODIFY_SURGERY_ASSIGNMENT_SUCCESS",
    MODIFY_SURGERY_ASSIGNMENT_FAILURE: "surgery_assignment/MODIFY_SURGERY_ASSIGNMENT_FAILURE",
    SURGERY_ASSIGNMENT_ERRORS_ON_SAVE: "surgery_assignment/SURGERY_ASSIGNMENT_ERRORS_ON_SAVE",
    LOAD_SURGEON_EXPERIENCE_REQUEST: "surgery_assignment/LOAD_SURGEON_EXPERIENCE_REQUEST",
    LOAD_SURGEON_EXPERIENCE_SUCCESS: "surgery_assignment/LOAD_SURGEON_EXPERIENCE_SUCCESS",
    LOAD_SURGEON_EXPERIENCE_FAILURE: "surgery_assignment/LOAD_SURGEON_EXPERIENCE_FAILURE",
    SET_IS_SURGEON_SELECTOR_OPEN: "surgery_assignment/SET_IS_SURGEON_SELECTOR_OPEN",
    LOAD_SURGEON_OPTIONS_REQUEST: "surgery_assignment/LOAD_SURGEON_OPTIONS_REQUEST",
    LOAD_SURGEON_OPTIONS_SUCCESS: "surgery_assignment/LOAD_SURGEON_OPTIONS_SUCCESS",
    LOAD_SURGEON_OPTIONS_FAILURE: "surgery_assignment/LOAD_SURGEON_OPTIONS_FAILURE"
};
