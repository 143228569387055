/**
 * get action items for timeslot canvas
 * @param {object} handlers
 * @param {object} isOpened
 * @return {array}
 */
const getActionItems = (handlers, isOpened) => {
    const actions = [
        {
            iconName: "DateRange",
            disabled: false,
            handler: handlers.handleOpenChange,
            translationKey: "TimeslotsActions.change",
            isOpened: isOpened.openChange
        },
        {
            iconName: "LineStyle",
            disabled: false,
            handler: handlers.handleOpenRooms,
            translationKey: "TimeslotsActions.rooms",
            isOpened: isOpened.openRooms
        },
        {
            iconName: "MenuBook",
            disabled: false,
            handler: handlers.handleOpenLegend,
            translationKey: "TimeslotsActions.legend",
            isOpened: isOpened.openLegend
        }
    ];
    return actions;
};

export {getActionItems};
