/**
 * @fileoverview redux selectors for timelots
 */

const selectTimeslotTable = (state) => state.timeslotTable;

const selectLoadError = (state) => selectTimeslotTable(state).loadError;
const selectLoadStatus = (state) => selectTimeslotTable(state).loadStatus;
const selectData = (state) => selectTimeslotTable(state).data;
const selectDateFrom = (state) => selectTimeslotTable(state).date;

export {selectLoadError, selectLoadStatus, selectData, selectDateFrom};
