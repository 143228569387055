// @ts-check
/**
 * @fileoverview API for timeslots service
 */

import axiosClient from "../../middleware/axios.middelware";

const URL_TIMESLOTS = "/orchestrator/hcservices/slots";
const URL_TIMESLOT_CHECK = "/orchestrator/hcservices/slots/check";
const URL_TIMESLOT_SAVE = "/orchestrator/hcservices/slots";
const URL_TIMESLOT_DELETE = "/orchestrator/hcservices/slots";

/**
 * fetch timeslots
 * @param {string} organizationId
 * @param {string} selectedDate in ISO8061 format ex. 2020-04-23
 * @return {Promise}
 */
function fetchAllTimeslots(organizationId, selectedDate) {
    const params = {
        organizationId,
        requestedDate: selectedDate
    };

    return axiosClient.get(URL_TIMESLOTS, {params});
}

/**
 * check next five slots and conflicts of timeslot
 * @param {object} data
 * @return {Promise}
 */
function checkTimeslots(data) {
    const params = {
        ...data
    };

    return axiosClient.get(URL_TIMESLOT_CHECK, {
        params
    });
}
/**
 * save timeslot
 * @param {object} data
 * @return {Promise}
 */
function saveTimeslots(data) {
    const params = {
        ...data
    };

    return axiosClient.post(URL_TIMESLOT_SAVE, params);
}
/**
 * delete timeslot
 * @param {object} data
 * @return {Promise}
 */
function deleteTimeslot(data) {
    return axiosClient.delete(URL_TIMESLOT_DELETE, {data});
}

export {fetchAllTimeslots, checkTimeslots, saveTimeslots, deleteTimeslot};
