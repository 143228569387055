// @ts-check
import cloneDeep from "lodash/cloneDeep";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {RoomPlanner} from "../../components/room_planner";
import {selectBlockedRooms} from "../../components/room_planner/room_planner_selectors";
import RoomPlannerDetails from "../../components/room_planner_details/components/room_planner_details";
import {clearConflictsAction} from "../../components/room_planner_details/room_planner_details_actions";
import ActionMenubar from "../../components/shared/action_menubar/action_menubar";
import DetailRight from "../../components/shared/detail_right/detail_right";
import Message from "../../components/shared/message/message";
import Page from "../../components/shared/page";
import WeekSelector from "../../components/shared/week_selector";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {PERMISSION, useSecurity} from "../../utils/security";
import {changeDate} from "./room_planner_actions";
import {selectRoomPlannerDateFrom, selectRoomPlannerDateTo} from "./room_planner_selectors";

/**
 * Page to show and edit rooms blockers
 * @return {React.ReactElement}
 */
const RoomPlannerPage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {now, startOf, format, endOf, fromISO} = useContext(DateContext);

    const organizationId = useSelector(selectCurrentOrganizationId);
    const periodFrom = useSelector(selectRoomPlannerDateFrom);
    const periodTo = useSelector(selectRoomPlannerDateTo);
    /** @type {Array<BlockedRoom>} */
    const blockedRooms = useSelector(selectBlockedRooms);

    const {isGranted} = useSecurity();

    const [isDialogVisible, setIsDialogVisible] = useState(false);
    /** @type [Array<AdjustedRoomBlockerSlot>, Function] */
    const [slots, setSlots] = useState([]);
    const [message, setMessage] = useState(null);
    const [showWeekend, setShowWeekend] = useState(false);
    const [showFullActionMenubar, setShowFullActionMenubar] = useState(false);

    useEffect(() => {
        if (!periodFrom) {
            dispatch(changeDate(startOf(now(), "week"), 7));
        }
        // if periodFrom is set
        if (periodFrom) {
            dispatch(changeDate(periodFrom, 7));
        }
    }, [periodFrom, periodTo, showWeekend]);

    const handleDateChange = (date) => {
        const newFrom = startOf(date, "week");
        dispatch(changeDate(newFrom, 7));
    };

    const renderHeaderOptions = () =>
        periodFrom &&
        periodTo && (
            <WeekSelector
                from={periodFrom}
                showWeekend={showWeekend}
                to={periodTo}
                onChange={handleDateChange}
                onToggleShowWeekend={toggleShowWeekend}
            />
        );

    const actions = isGranted(PERMISSION.MODIFY_LOCATION)
        ? [
              {
                  iconName: "AccessTime",
                  disabled: false,
                  handler: () => handleAdd(now(), "", false),
                  translationKey: "RoomPlanner.addBlocking",
                  isOpened: isDialogVisible
              }
          ]
        : [];

    /**
     * handler for adding blocker
     * @param {DateTimeType} date
     * @param {String} roomIdHash
     * @param {Boolean} keepOpen - set true if add button in the layer is clicked
     */
    const handleAdd = (date, roomIdHash, keepOpen) => {
        // Close the layer and reset
        if (isDialogVisible && !keepOpen) {
            dispatch(clearConflictsAction());
            setSlots([]);
            setIsDialogVisible(false);

            // Toggle the layer and set slots
        } else {
            const newSlots = cloneDeep(slots);
            newSlots.push({
                start: startOf(date, "day"),
                end: endOf(date, "day"),
                roomIdHash: roomIdHash,
                interval: "none",
                isNew: true,
                createdAt: format(now(), DATE_FORMATS.ISO_DATE) // used for key
            });
            setSlots(newSlots);

            if (!keepOpen) {
                setIsDialogVisible(!isDialogVisible);
            }
        }
    };

    /**
     * handler for setting slots
     * @param {Array<String>} blockerIds
     * @param {{id: String, name: String}} room
     */
    const handleEdit = (blockerIds, room) => {
        const blockedRoom = blockedRooms.find((blockedRoom) => blockedRoom._id === room.id);

        const editSlots = [];
        for (const blockerId of blockerIds) {
            const blockingDate = blockedRoom ? blockedRoom.blockingDates.find((date) => date._id === blockerId) : {};
            const start =
                blockingDate.seriesId && blockingDate.interval !== "none" ? fromISO(blockingDate.seriesStart) : fromISO(blockingDate.start);
            const end =
                blockingDate.seriesId && blockingDate.interval !== "none" ? fromISO(blockingDate.seriesEnd) : fromISO(blockingDate.end);

            if (blockingDate) {
                editSlots.push({
                    blockerId: blockingDate._id, // must be same as blockerId
                    roomIdHash: blockedRoom._id,
                    seriesId: blockingDate.seriesId,
                    reason: blockingDate.reason,
                    start,
                    end,
                    interval: blockingDate.interval,
                    isNew: false
                });
            }
        }
        // Reset conflicts if closed
        if (isDialogVisible) {
            dispatch(clearConflictsAction());
            setSlots([]);
        }

        if (editSlots.length) {
            setSlots(editSlots);
            setIsDialogVisible(!isDialogVisible);
        }
    };

    const handleClose = () => {
        dispatch(clearConflictsAction());
        setIsDialogVisible(!isDialogVisible);
        setSlots([]);
    };

    /**
     * handler to toggle showWeekend
     */
    const toggleShowWeekend = () => {
        setShowWeekend(!showWeekend);
    };

    /**
     * handler to toggle action menubar width
     */
    const toggleActionMenubar = () => {
        setShowFullActionMenubar(!showFullActionMenubar);
    };
    return (
        <Fragment>
            {periodFrom && (
                <Page
                    fullActionMenubar={showFullActionMenubar}
                    fullCanvas
                    name="roomPage"
                    openRightLayer={isDialogVisible}
                    organizationId={organizationId}
                    renderOptions={renderHeaderOptions}
                    title={t("RoomPlannerPage.title")}
                >
                    {isDialogVisible && (
                        <DetailRight fullActionMenubar={showFullActionMenubar} open={isDialogVisible} onClose={handleClose}>
                            <RoomPlannerDetails setMessage={setMessage} slots={slots} onAdd={handleAdd} />
                        </DetailRight>
                    )}
                    <RoomPlanner
                        openRightLayer={isDialogVisible}
                        periodFrom={periodFrom}
                        periodTo={periodTo}
                        showWeekend={showWeekend}
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                    />
                    <ActionMenubar actions={actions} showFullActionMenubar={showFullActionMenubar} onToggleWidth={toggleActionMenubar} />
                    {message && <Message message={message.text} severity={message.severity} onClose={() => setMessage("")} />}
                </Page>
            )}
        </Fragment>
    );
};

export default RoomPlannerPage;
